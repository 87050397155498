import * as React from "react"
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  return (<>
    {"404"}
  </>)
}

export default NotFoundPage
